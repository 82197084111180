import { Component } from 'react'
import {Redirect} from 'react-router-dom'
import Cookies from 'js-cookie'
import Popup from 'reactjs-popup'
import Header from '../Header'
import ServiceItem from '../ServiceItem'

import { IoIosAdd } from "react-icons/io";
import { Oval } from 'react-loader-spinner';

import './index.css'

const services = ['Printing', 'Binding', 'Lamination','Photoshop']

const defaultSubService = {
    Printing:['color','black&white'],
    Binding:['spiral','book'],
    Lamination:['A4','A6'],
    Photoshop:['default size']
}

const apiStatus = {
    initial: "INITIAL",
    inProgress: "INPROGRESS",
    success: "SUCCESS",
    failure: "FAILURE",
}

class ProfilePage extends Component {
    state = {
        newUpi: '',
        upiPassword: '',
        shopId: '',
        shopName: '',
        city: '',
        mobileNo: '',
        mailId: '',
        shopErrMsg: '',
        addServiceStatus:apiStatus.initial,
        ssl:[],
        subServicesList:[],
        serviceArray:{
            selectedServiceName:'',
            selectedServices:[],
            bulkOrder:true,
            serviceErrorMsg:''
        }
    }

    componentDidMount = () => {
        this.getData()
    }

    getData = async () => {
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/profile/shop-profile`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "GET"
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            const { shopId, shopName, shopAddress, mobileNo, email } = data.shop;
            this.setState({
                shopId: shopId, city: shopAddress, shopName: shopName, mobileNo: mobileNo, mailId: email,
            },this.getServicesList)
        }
        else {
            console.log("error")
        }
    }

    getServicesList = async () =>{
        const {shopId} = this.state
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/services-list`
        const options = {
            headers:{
                Authorization:`Bearer ${token}`,
                'Content-type':'application/json'
            },
            method:"GET",
        }
        const response = await fetch(url,options)
        const responseData = await response.json()
        if(response.ok){
            const{services} = responseData
            this.setState({ssl:services})
        }
        else{
            console.log("error")
        }
    }

    onInputChange = (event) => {
        const { id, value } = event.target;
        if (id === 'bulkorder') {
            this.setState((prevState) => ({
                serviceArray: {
                    ...prevState.serviceArray,
                    bulkOrder: {
                        ...prevState.serviceArray.bulkOrder,
                        bulkQuantity: value,
                    },
                },
            }));
        } else {
            // For other inputs
            this.setState({ [id]: value });
        }
    };
    

    profileValidations = () => {
        const { shopName, city, mobileNo } = this.state
        if (shopName === '') return '*Please enter the shopName'
        else if (city === '') return '*Please enter the city'
        else if (mobileNo.length !== 10) return '*mobileNo digits must be 10'
        else return ''
    }

    onClickSaveProfile = async () => {
        const { shopName, city, mobileNo } = this.state
        const validated = this.profileValidations()
        if (validated !== '') {
            this.setState({ shopErrMsg: validated })
            return
        }
        else {
            this.setState({ shopErrMsg: '' })
        }
        const details = { shopName, shopAddress: city, mobileNo }
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/api/profile/shop-profile-update`
        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(details),
        }
        const response = await fetch(url, options);
        const data = await response.json()
        if (response.ok) {
            alert("Profile is Updated")
        }
        else {
            console.log(data)
            alert("cant able update profile")
        }
    }
    
    //chage UPI ID
    onClickUpiProceed = async () => {
        const token = Cookies.get("mitra_token")
        const { upiPassword, newUpi } = this.state
        const details = { password: upiPassword, upi: newUpi }
        const url = `${process.env.REACT_APP_BASE_URL}/profile/shop-profile-update/upi`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(details)
        }
        const response = await fetch(url, options)
        const upiData = await response.json()
        if (response.ok) {
            alert(upiData.message)
            this.setState({ newUpi: '', upiPassword: '' })
        }
        else {
            alert(upiData.error)
        }
    }

    //codes 
    //SHOP DETAILS CODE
    renderShopDetailsContainer = () =>{
        const {shopErrMsg,newUpi, upiPassword,mailId, shopName, city, mobileNo} = this.state

        return(
            <div className='services-container'>
                <h1>Shop Details</h1>
                <p>Information you provide will be used for the purpose of privacy and security.</p>
                <div className=''>
                    <div className='shop-service-boxes'>
                        <div className='input-set'>
                            <label className='shop-profile-label' htmlFor="shopName">Shop Name</label><br />
                            <input className='shop-profile-input' value={shopName} onChange={this.onInputChange} placeholder='Enter Name' type="text" id="shopName" />
                        </div>
                        <div className='input-set'>
                            <label className='shop-profile-label' htmlFor="city">City</label><br />
                            <input className='shop-profile-input' value={city} onChange={this.onInputChange} placeholder='Enter City' type="text" id="city" />
                        </div>
                    </div>
                    <div className='shop-service-boxes'>
                        <div className='input-set'>
                            <label className='shop-profile-label' htmlFor="mobileNo">Mobile No</label><br />
                            <input className='shop-profile-input' value={mobileNo} onChange={this.onInputChange} placeholder='Enter Mobile No' type="number" id="mobileNo" />
                        </div>
                        <div className='input-set'>
                            <label className='shop-profile-label' htmlFor="mailId">Gmail</label><br />
                            <input className='shop-profile-input' value={mailId} onChange={this.onInputChange} placeholder='Enter Mail' type="text" />
                            <p className="email-note">You cannot update email address</p>
                        </div>
                    </div>
                </div>
                <button onClick={this.onClickSaveProfile} className='save-btn'>Save</button>
                <Popup
                    modal
                    trigger={
                            <button type="button" className="upi-btn">Change UPI ID</button>
                            }
                >
                    {close => (
                        <>
                            <div className='upi-completed-popup'>
                                <div className='upi-service-boxes'>
                                    <div className='input-set'>
                                        <label className='profile-label' htmlFor="upiPassword">PASSWORD :</label><br />
                                        <input className='profile-input' value={upiPassword} onChange={this.onInputChange} placeholder='ENTER PASSWORD' type="password" id="upiPassword" />
                                    </div>
                                    <div className='input-set'>
                                        <label className='profile-label' htmlFor="newUpi">NEW UPI ID :</label><br />
                                        <input className='profile-input' value={newUpi} onChange={this.onInputChange} placeholder='ENTER UPI ID' type="text" id="newUpi" />
                                    </div>
                                </div>
                                <div className="popup-btn-container">
                                    <button type="button" className="print-all-btn" onClick={() => close()}>
                                        Cancel
                                    </button>
                                    <button type="button" className="print-all-btn" onClick={this.onClickUpiProceed}>Proceed</button>
                                </div>
                            </div>
                        </>
                            )}
                </Popup>
                {shopErrMsg !== '' && <p className='save-error-msg'>{shopErrMsg}</p>}
            </div>
        )
    }

    ///////////////POPUP ADD SERVICES CODE COMPONENTS//////////////////

    handleCheckboxChange = (subServiceName) => {
        const { serviceArray } = this.state;
        const { selectedServiceName,selectedServices } = serviceArray;
    
        const isServiceSelected = selectedServices.some(s => s.name === subServiceName);
    
        if (isServiceSelected) {
            this.setState({
                serviceArray: {
                    ...serviceArray,
                    selectedServices: selectedServices.filter((s) => s.name !== subServiceName)
                }
            });
        } else {
            if(selectedServiceName==="Printing"){
                this.setState({
                    serviceArray: {
                        ...serviceArray,
                        selectedServices: [...selectedServices, { name: subServiceName,singleSidePrice:0,doubleSidePrice:0, price: 0,bulkPricing:{enabled:true,threshold:0,blukPrice:0} }]                                                                                            
                    }
                });
            } else {
                this.setState({
                    serviceArray: {
                        ...serviceArray,
                        selectedServices: [...selectedServices, { name: subServiceName,singleSidePrice:0,doubleSidePrice:0, price: 0 }]
                    }
                });
            }
        }
    };

    onChangeBulkServices = (event) =>{
        const { serviceArray } = this.state;
        this.setState({serviceArray:{...serviceArray,bulkOrder:event.target.value}})
    }
    
    updateServiceApi = async (newService,shopId,serviceId) => {
        const changedData = newService
        const token = Cookies.get("mitra_token");
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/update-services/${serviceId}`;
        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(changedData),
        };
    
        try {
            const response = await fetch(url, options);
            const data = await response.json();
    
            if (response.ok) {
                this.setState({addServiceStatus:apiStatus.success})
                window.location.reload();
            } else {
                console.error('Error:', data);
                this.setState({ ServicesErrMsg: data.error || 'Something went wrong!' });
            }
        } catch (error) {
            console.error('Network error:', error);
            this.setState({ ServicesErrMsg: 'Network error, please try again.' });
        }
        <Redirect to='/profile' />
    };
    

    onClickAddSelectedServices = async () =>{
        const { serviceArray,shopId, ssl } = this.state;
        const {selectedServiceName,selectedServices,bulkOrder} = serviceArray


        if (!selectedServiceName) {
            this.setState({
                serviceArray: { ...serviceArray, serviceErrorMsg: 'Please select a service.' }
            });
            return;
        }
        if (selectedServices.length === 0) {
            this.setState({
                serviceArray: { ...serviceArray, serviceErrorMsg: 'Please select at least one sub-service.' }
            });
            return;
        }

        const updatedServices = selectedServices.map((service) => {
            if (selectedServiceName === "Printing") {
                return { ...service, bulkPricing: { ...service.bulkPricing, enabled: bulkOrder } };
            }
            return service;
        });

        const addedServiceData = {
            category:selectedServiceName,
            options:updatedServices,
        }

        //UPDATE SERVICE API CODE
        const isServiceExist = ssl.find(eachItem => eachItem.category===addedServiceData.category)
        if(isServiceExist){
            const {serviceId} = isServiceExist
            const newService = {options:updatedServices}
            this.updateServiceApi(newService,shopId,serviceId)
            return 
        }

        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/add-services`;
        const options = {
            method:"POST",
            headers:{
                Authorization:`Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body:JSON.stringify(addedServiceData)
        }
        const response = await fetch(url,options)
        if(response.ok){
            this.setState({addServiceStatus:apiStatus.success,
                serviceArray:{selectedServiceName:'',selectedServices:[],bulkPricing:{bulkQuantity:0,bulkQuantityPrice:0}}
            })
            this.getData()
        }
        else{
            console.log("error")
            const responseData = await response.json()
            const {error} = responseData
            this.setState({
                serviceArray: { ...serviceArray, serviceErrorMsg: error || 'An error occurred while adding the service.' },
            });
        }
    }

    onChangeSelectedServices = (e) => {
        const { serviceArray } = this.state;
        const selectedServiceName = e.target.value;
        this.setState({
            serviceArray: { ...serviceArray, selectedServiceName,selectedServices:[] },
            subServicesList: defaultSubService[selectedServiceName] || [],
        });
    }
    

    renderServicesPopUp = (close) => {
        const { serviceArray, subServicesList, ssl } = this.state;
        const { selectedServiceName, selectedServices, bulkOrder, serviceErrorMsg } = serviceArray;
    
        // Filter existing subservices for the selected service
        const existingSubservices = ssl
            .filter(service => service.category === selectedServiceName)
            .flatMap(service => service.options.map(option => option.name));
    
        return (
            <div className="services-popup">
                <h3 className="add-service-head">ADD SERVICE</h3>
                <form>
                    <div>
                        <label className="profile-label" htmlFor="servicesDropdown">Select Service:</label><br />
                        <select
                            id="servicesDropdown"
                            className="profile-input"
                            onChange={this.onChangeSelectedServices}
                            value={selectedServiceName || ''}
                        >
                            <option value="" disabled>Select a service</option>
                            {services.map((service) => (
                                <option key={service} value={service}>
                                    {service}
                                </option>
                            ))}
                        </select>
                    </div>
                    {selectedServiceName && (
                        <div className="bulk-container">
                            <label className="profile-label" htmlFor="subServices">Sub-services:</label><br />
                            {subServicesList.map((subService) => (
                                <div key={subService}>
                                    <input
                                        type="checkbox"
                                        id={subService}
                                        checked={
                                            selectedServices.some(s => s.name === subService) || 
                                            existingSubservices.includes(subService)
                                        }
                                        onChange={() => this.handleCheckboxChange(subService)}
                                    />
                                    <label htmlFor={subService}>{subService}</label>
                                </div>
                            ))}
                        </div>
                    )}
                    {(selectedServices.length > 0 && selectedServiceName === "Printing") && (
                        <div className="bulk-container">
                            <label className="profile-label" htmlFor="bulkorder">Enter Bulk Quantity:</label><br />
                            <select
                                id="bulkServiceDropDown"
                                className="profile-input"
                                onChange={this.onChangeBulkServices}
                                value={bulkOrder || ''}
                            >   <option value="false" disabled>Choose</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    )}
                </form>
                <button className="add-service-popup-save-btn" onClick={this.onClickAddSelectedServices}>Submit</button>
                <button className="add-service-popup-save-btn" onClick={close}>Close</button>
                {serviceErrorMsg && <p className="save-error-msg">{serviceErrorMsg}</p>}
            </div>
        );
    };    
    

    renderLoadingView = () => (
        <div className='success-services-popup'>
            <div className='loader-spinner'>
                <Oval height={80} width={80} color="blue" ariaLabel="loading" />
            </div>
        </div>
    )

    closePopUpSuccessView = (close) =>{
        close()
        this.setState({addServiceStatus:apiStatus.initial})
    }

    renderAddServiceSuccessPopUp = (close) =>{
        return(
            <div className='success-services-popup'>
                <img className='success-img' src="https://w7.pngwing.com/pngs/688/951/png-transparent-correct-mark-tick-icon-thumbnail.png" alt="Added" />
                <h1 className='add-service-head'>SERVICE ADDED SUCCESSFULLY</h1>
                <button className='add-service-popup-save-btn' onClick={() => this.closePopUpSuccessView(close)}>Close</button>
            </div>
        )
    }
    


    render() {
        const {ssl, addServiceStatus,shopId} = this.state

        let addServicePopUpCode = ''

        switch(addServiceStatus) {
            case (apiStatus.initial) :
                addServicePopUpCode = this.renderServicesPopUp
                break;
            case (apiStatus.inProgress) : 
                addServicePopUpCode = this.renderLoadingView
                break;
            case (apiStatus.success) :
                addServicePopUpCode = this.renderAddServiceSuccessPopUp
                break;
            default:
                addServicePopUpCode = null;

        }

        return (
            <div className='profile'>
                <Header />
                <div className='profile-page'>
                    {this.renderShopDetailsContainer()}
                    <div className='services-container'>
                        <div className='service-head-container'>
                            <div>
                                <h1>Shop Services</h1>
                                <p>We will use the details you provide to ensure customers can access and use your services.</p>
                            </div>
                            <Popup
                            modal
                            trigger={
                                <button className='add-service-btn'><IoIosAdd className='add-btn' /> Add Services</button>
                            }
                            >
                            {close => (
                                    <>
                                        {addServicePopUpCode ? addServicePopUpCode(close) : null}
                                    </>
                            )}
                            </Popup>
                        </div>
                        <div>
                            {ssl.map(eachItem => <ServiceItem key={eachItem.serviceId} serviceData={eachItem} shopId={shopId} onClickrenderAllServices={this.getServicesList} /> )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilePage