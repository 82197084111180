import { RxCross2 } from "react-icons/rx";
import { LiaPiggyBankSolid } from "react-icons/lia";

import './index.css'

const TransactionHistoryItem = props =>{
    const {value} = props 
    const {amount,status,_id,createdAt} = value

    return(
        <li className="transaction-history-list-item">
            <div className="transaction-history-item-details">
                <h1 className="transaction-history-name">{createdAt}</h1>
                <p className="transaction-history-email">{_id}</p>
            </div>
            <p className="transaction-history-type">{status}</p>
            <div className="transaction-items">
                <LiaPiggyBankSolid className="transaction-pig" />
                <p className="transaction-price">-{amount} rs</p>
                <button className="transaction-price-btn">-{amount} rs</button>
                <button type="button" className="transaction-cross-btn"><RxCross2 /></button>               
            </div>
        </li>
    )
}

export default TransactionHistoryItem