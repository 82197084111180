import {Component} from 'react'
import Cookies from 'js-cookie'
import { Oval } from 'react-loader-spinner';
import Popup from 'reactjs-popup'
import Header from '../Header'
import Footer from '../Footer'
import TransactionHistoryItem from '../TransactionHistoryItem'

import { FaRupeeSign } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import './index.css'

const apiStatus = {
    initial:"INITIAL",
    inProgress:"INPROGRESS",
    success:"SUCCESS",
    failure:"FAILURE",
}

const withdrawalStatusConstants = {
    initial: 'INITIAL',
    inProgress: 'INPROGRESS',
    completed: 'COMPLETED'
}

class TransactionHistory extends Component{
    state = {list:[],status:apiStatus.success,balance:'xxx',withdrawlAmount: 0, withdrawalStatus: withdrawalStatusConstants.initial, withDrawResult: {}}

    componentDidMount = async () =>{
        this.getData()
        this.getCurrentBalance()
    }

    getCurrentBalance = async () => {
        const token = Cookies.get('mitra_token')
        const url = `${process.env.REACT_APP_BASE_URL}/profile/shop-home`
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok === true) {
            this.setState({ balance: data.walletBalance})
        } else {
            console.log("Got error while fetching")
        }
    }

    getData = async () =>{
        this.setState({status:apiStatus.inProgress})
        const token = Cookies.get("mitra_token");

        const url = `${process.env.REACT_APP_BASE_URL}/wallet/get-withdrawal-requests`
        const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            method: 'GET',
        }
        const response = await fetch(url,options)
        const data = await response.json()
        if(response.ok){
            const sortedData = data.withdrawalRequests.sort((a, b) => {
                const dateA = this.parseDate(a.createdAt);
                const dateB = this.parseDate(b.createdAt);
                return dateB - dateA;
            });
            this.setState({list:sortedData,status:apiStatus.success})
        }
        else{
            this.setState({status:apiStatus.failure})
        }
    }

    parseDate = (dateStr) => {
        const [datePart, timePart] = dateStr.split(", ");
        const [day, month, year] = datePart.split("/");
        const formattedDate = `${year}-${month}-${day} ${timePart}`;
        return new Date(formattedDate);
    };


    onClickRetry = () => {
        window.location.reload()
    }


    onChangeAmount = (event) => {
        this.setState({ walletAmount: event.target.value })
    }

    onClickWithDraw = async () => {
        const { withdrawlAmount } = this.state
        if(withdrawlAmount<10){
            alert("amount should be greater than 10Rs")
            return 
        }
        this.setState({ withdrawalStatus: withdrawalStatusConstants.inProgress })
        const details = { amount: withdrawlAmount }
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/wallet/withdrawal-request`;
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(details)
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            this.setState({ withDrawResult: data, withdrawlAmount: 0,  withdrawalStatus: withdrawalStatusConstants.completed })
        }
        else {
            this.setState({withDrawResult: data, withdrawlAmount: 0, withdrawalStatus: withdrawalStatusConstants.completed })
            console.log("can not update shop status")
        }
        this.getCurrentBalance()
    }

    //Rendering withdraw view

    renderLoadingView = () => (
        <div className='loader-spinner'>
            <Oval height={80} width={80} color="blue" ariaLabel="loading" />
        </div>
    )

    renderWithDrawlCompleted = () => {
        const { withDrawResult } = this.state
        return (
            <div>
                <p>{withDrawResult.msg}</p>
            </div>
        )
    }

    renderWithDrawlInitialView = () => {
        const {withdrawlAmount} = this.state
        return (
            <div >
                <p>Enter Amount:</p>
                <input type="number" value={withdrawlAmount} onChange={this.onChangeWithdrawlAmount} placeholder='ENTER AMOUNT' /> <br />
                <div className="popup-btn-container">
                    <button type="button" className="wallet-popup-withdraw-btn" onClick={this.onClickWithDraw}>Withdraw</button>
                </div>
            </div>
        )
    }

    onClickClose = (close) => {
        this.setState({withdrawalStatus: withdrawalStatusConstants.initial})
        close()
    }

    render() {
        const {list,status,balance,withdrawalStatus} = this.state

        const roundedBalance = Math.round(balance,2)

        let code

        switch(status){
            case apiStatus.inProgress:
                code = (
                    <div className='oval-loader'>
                        <Oval height={45} width={45} color="#0d4c8c" ariaLabel="loading"/>
                    </div>
                )
                break
            case apiStatus.success:
                if(list.length===0){
                    code = (
                        <div className="transaction-no-orders-view">
                          <img
                            src="https://assets.ccbp.in/frontend/react-js/no-jobs-img.png"
                            alt="no orders"
                            className='transaction-no-orders-view-img'
                          />
                          <h1>No History Found</h1>
                          <p>We could not find any Orders History.</p>
                        </div>
                      )
                } else{
                    code = (
                        <ul className='transaction-history-ul-list-container'>
                            <h1 className='transaction-history-heading'>Transactions</h1>
                            {list.map(eachItem => <TransactionHistoryItem value={eachItem} key={eachItem.createdAt} />)}
                        </ul>
                    )
                }
                break
            case apiStatus.failure:
                code = (
                    <div className="transaction-no-orders-view">
                      <img
                        src="https://assets.ccbp.in/frontend/react-js/failure-img.png"
                        alt="failure view"
                        className='transaction-no-orders-view-img'
                      />
                      <h1>Oops! Something Went Wrong</h1>
                      <p>We cannot seem to find the page you are looking for</p>
                      <button className="transaction-retry-btn" onClick={this.onClickRetry} type="button">
                        Retry
                      </button>
                    </div>
                  )
                break 
            default : 
                code = null 
        }

        let withDrawlView;
        switch (withdrawalStatus) {
            case withdrawalStatusConstants.completed:
                withDrawlView = this.renderWithDrawlCompleted()
                break
            case withdrawalStatusConstants.inProgress:
                withDrawlView = this.renderLoadingView()
                break
            case withdrawalStatusConstants.initial:
                withDrawlView = this.renderWithDrawlInitialView()
                break
            default:
                withDrawlView = null
        }


        return(
            <div>
                <Header />
                <div className="transaction-history-page-container">
                    <div className='transaction-history-bottom-container'>
                        <div className="transaction-balance-payment-container">
                            <h1 className='transaction-wallet-amount'>Balance Payment</h1>
                            <h1 className='transaction-wallet-amount'><FaRupeeSign /> {roundedBalance}</h1>
                        </div>
                        {code}
                        <Popup
                            modal
                            trigger={
                                <button className="transaction-history-btn">Withdraw</button>
                            }
                        >
                            {close => (
                                <div className='wallet-popup'>
                                    <button type="button" className="wallet-popup-cross-btn" onClick={() => this.onClickClose(close)}>
                                        <RxCross2 />
                                    </button>
                                    {withDrawlView}
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default TransactionHistory