import {Redirect} from 'react-router-dom'
import { Component } from 'react'
import Cookies from 'js-cookie';
import Popup from 'reactjs-popup'
import { Oval } from 'react-loader-spinner';
import { Switch } from 'antd'

import Header from '../Header'
import Footer from '../Footer'
import OrderItem from '../OrderItem'

import { IoWalletOutline } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import { GrTransaction } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";

import { parse } from 'date-fns';

import './index.css'

const apiStatus = {
    initial: "INITIAL",
    inProgress: "INPROGRESS",
    success: "SUCCESS",
    failure: "FAILURE",
}

const withdrawalStatusConstants = {
    initial: 'INITIAL',
    inProgress: 'INPROGRESS',
    completed: 'COMPLETED'
}

class Home extends Component {
    state = { isActive: false, searchValue: '', list: [], status: apiStatus.initial, selectedOption: 'all', walletDetails: {}, withdrawlAmount: 0, withdrawalStatus: withdrawalStatusConstants.initial, withDrawResult: {} }

    componentDidMount = async () => {
        this.getData()
        this.getCurrentBalance()
    }

    getCurrentBalance = async () => {
        const token = Cookies.get('mitra_token')
        const url = `${process.env.REACT_APP_BASE_URL}/profile/shop-home`
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok === true) {
            this.setState({ walletDetails: data,isActive:data.isOpen })
        } else {
            console.log("Got error while fetching")
        }
    }

    getData = async () => {
        this.setState({ status: apiStatus.inProgress })
        const token = Cookies.get("mitra_token");

        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
                const sortedData = data.sort((a, b) => {
                const dateA = parse(a.date, "d MMMM yyyy 'at' h:mm a", new Date());
                const dateB = parse(b.date, "d MMMM yyyy 'at' h:mm a", new Date());
                return dateB - dateA;
            });
            console.log(sortedData)
            this.setState({ list: sortedData, status: apiStatus.success })
        }
        else {
            this.setState({ status: apiStatus.failure })
        }
    }

    onClickLastTransactions = () =>{
        const {history} = this.props 
        history.push('/transactionhistory')
     }

    onChangeActive = async () => {
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/toggle-status`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "PUT",
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            console.log("shop status updated")
        }
        else {
            console.log(data)
            console.log("can not update shop status")
        }
        this.getCurrentBalance()
    }

    onChangeAmount = (event) => {
        this.setState({ walletAmount: event.target.value })
    }

    //AMOUNT WITHDRAW

    onClickWithDraw = async () => {
        const { withdrawlAmount } = this.state
        if(withdrawlAmount<10){
            alert("amount should be greater than 10Rs")
            return 
        }
        this.setState({ withdrawalStatus: withdrawalStatusConstants.inProgress })
        const details = { amount: withdrawlAmount }
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/wallet/withdrawal-request`;
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(details)
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            this.setState({ withDrawResult: data, withdrawlAmount: 0,  withdrawalStatus: withdrawalStatusConstants.completed })
        }
        else {
            this.setState({withDrawResult: data, withdrawlAmount: 0, withdrawalStatus: withdrawalStatusConstants.completed })
            console.log("can not update shop status")
        }
        this.getCurrentBalance()
    }

    onChangeSearchValue = event => {
        this.setState({ searchValue: event.target.value })
    }

    onClickRetry = () => {
        window.location.reload()
    }

    onChangeType = (event) => {
        this.setState({ selectedOption: event.target.value })
    }

    onChangeWithdrawlAmount = (event) => {
        this.setState({ withdrawlAmount: event.target.value })
    }

    //orderItem page handlers................................................

    //printed btn status updation

    handelPrintedBtn = async (orderId) => {
        this.setState({status: apiStatus.inProgress})
        const token = Cookies.get("mitra_token");
        const details = { status: "Printed" };
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders/${orderId}/status`;

        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(details),
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json()
            if (response.ok) {
                this.setState({status: apiStatus.success})
                console.log('Status updated successfully');
            } else {
                console.log(data)
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error during status update:', error);
        }
        this.getData()
    }

    //CANCEL btn

    handelCancelBtn = async (orderId) => {
        const token = Cookies.get("mitra_token");
        const details = { status: "Rejected" };
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders/${orderId}/status`;

        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(details),
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json()
            if (response.ok) {
                console.log('Status updated successfully');
            } else {
                console.log(data)
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error during status update:', error);
        }
        this.getData()
    }

    //COMPLETED BTN

    proceedCodOrder = async (orderId) =>{
        const token = Cookies.get("mitra_token");
        const details = { status: "Completed" };
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders/${orderId}/status`;

        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(details),
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json()
            if (response.ok) {
                console.log('Status updated successfully');
            } else {
                console.log(data)
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error during status update:', error);
        }
        this.getData()
    }

    handelCompletedBtn = async (orderId, otp, paymentMethod) => {
        if(paymentMethod === "COD"){
            this.proceedCodOrder(orderId)
        }
        else{if(otp===""){
            alert("Enter OTP")
            return 
        }
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders/${orderId}/status`;
        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                status: 'Completed',
            })
        }
        const response = await fetch(url, options)
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            alert("your order is completed")
        }
        else {
            alert("your order is not completed")
        }}
        this.getData()
    }

    //Rendering withdraw view

    renderLoadingView = () => (
        <div className='loader-spinner'>
            <Oval height={80} width={80} color="blue" ariaLabel="loading" />
        </div>
    )

    renderWithDrawlCompleted = () => {
        const { withDrawResult } = this.state
        return (
            <div>
                <p>{withDrawResult.msg}</p>
            </div>
        )
    }

    renderWithDrawlInitialView = () => {
        const {withdrawlAmount} = this.state
        return (
            <div >
                <p>Enter Amount:</p>
                <input type="number" value={withdrawlAmount} onChange={this.onChangeWithdrawlAmount} placeholder='ENTER AMOUNT' /> <br />
                <div className="popup-btn-container">
                    <button type="button" className="wallet-popup-withdraw-btn" onClick={this.onClickWithDraw}>Withdraw</button>
                </div>
            </div>
        )
    }

    onClickClose = (close) => {
        this.setState({withdrawalStatus: withdrawalStatusConstants.initial})
        close()
    }

    //rendering Orders List

    renderOrdersListSuccessView = (searchedList) =>{
        return (
            <ul className="orders-list-container">
                <div className='head-container'>
                    <h1 className="orders-heading">Open Orders</h1>
                    <div className='total-container'>
                        <h1 className='total'>{searchedList.length}</h1>
                        <h1 className='total-status'>In Progress</h1>
                    </div>
                </div>
                <hr className="lines" />
                <div className="table-values">
                    <p>Username</p>
                    <p>Order ID</p>
                    <p>Amount</p>
                    <p>Files</p>
                    <p>Info</p>
                </div>
                <hr className="lines" />
                {searchedList.map(eachItem => <OrderItem value={eachItem} key={eachItem.orderId} handelPrintedBtn={this.handelPrintedBtn} handelCancelBtn={this.handelCancelBtn} handelCompletedBtn={this.handelCompletedBtn} />)}
            </ul>
        )
    }

    renderOrdersListSuccessNoOrdersView = (searchedList) =>{
        return (
            <ul className="orders-list-container">
                <div className='head-container'>
                    <h1 className="orders-heading">Open Orders</h1>
                        <div className='total-container'>
                            <h1 className='total'>{searchedList.length}</h1>
                            <h1 className='total-status'>In Progress</h1>
                        </div>
                </div>
                <hr className="lines" />
                <div className="table-values">
                    <p>Username</p>
                    <p>Order ID</p>
                    <p>Amount</p>
                    <p>Files</p>
                    <p>Info</p>
                </div>
                <hr className="lines" />
                <img
                    src="https://assets.ccbp.in/frontend/react-js/no-jobs-img.png"
                    alt="no orders"
                    className='no-orders-view-img'
                />
                <h1>No Orders Found</h1>
                <p>We could not find any Orders.</p>
            </ul>
        )
    }

    renderOrdersListFailureView = () =>{
        return (
            <div className="no-orders-view">
                <img
                    src="https://assets.ccbp.in/frontend/react-js/failure-img.png"
                    alt="failure view"
                    className='no-orders-view-img'
                />
                <h1>Oops! Something Went Wrong</h1>
                <p>We cannot seem to find the page you are looking for</p>
                <button className="retry-btn" onClick={this.onClickRetry} type="button">
                    Retry
                </button>
            </div>
        )
    }


    render() {
        const token = Cookies.get("mitra_token")
        if(token===undefined){
            <Redirect to="/login" />
        }
        const { isActive, searchValue, list, status, selectedOption, walletDetails, withdrawalStatus } = this.state
        const { shopId, walletBalance, shopAddress } = walletDetails
        const roundedWalletBalance = Math.round(walletBalance,2)

        //dropDown list
        let newList;
        if (selectedOption === "all") {
            newList = list.filter(eachItem => {
                if (eachItem.status === "Printed" || eachItem.status === "Confirmed" || eachItem.status === "Pending") {
                    return eachItem
                }
                else {
                    return null
                }
            })
        } else if (selectedOption === "printed") {
            newList = list.filter(eachItem => {
                if (eachItem.status === "Printed") {
                    return eachItem
                }
                else {
                    return null
                }
            })
        }
        else {
            newList = list.filter(eachItem => {
                if (eachItem.status === "Confirmed") {
                    return eachItem
                }
                else {
                    return null
                }
            })
        }


        //search
        const searchedList = newList.filter(eachItem => {
            const customOrderIdNumber = eachItem.customOrderId.replace(/\D/g, '');
            return (
                customOrderIdNumber.includes(searchValue) || 
                eachItem.customerName.toLowerCase().includes(searchValue.toLowerCase())
            );
        });


        //Orders List
        let code;
        switch (status) {
            case apiStatus.inProgress:
                code = this.renderLoadingView()
                break
            case apiStatus.success:
                if (searchedList.length === 0) {
                    code = this.renderOrdersListSuccessNoOrdersView(searchedList)
                } else {
                    code = this.renderOrdersListSuccessView(searchedList)
                }
                break
            case apiStatus.failure:
                code = this.renderOrdersListFailureView()
                break
            default:
                code = null
        }

        //withdrawl
        let withDrawlView;
        switch (withdrawalStatus) {
            case withdrawalStatusConstants.completed:
                withDrawlView = this.renderWithDrawlCompleted()
                break
            case withdrawalStatusConstants.inProgress:
                withDrawlView = this.renderLoadingView()
                break
            case withdrawalStatusConstants.initial:
                withDrawlView = this.renderWithDrawlInitialView()
                break
            default:
                withDrawlView = null
        }

        return (
            <div className="bg-home">
                <Header />
                <div className="main-content">
                    <div className="upper-container">
                        <div>
                            <p className='store-details'>STORE ID: <span className='span-store'>{shopId}</span></p>
                            <p className='store-details'>LOCATION: <span className='span-store'>{shopAddress}</span></p>
                        </div>
                        <hr className="lines" />
                        <Popup
                            modal
                            trigger={
                                <div className="wallet-container">
                                    <IoWalletOutline className='wallet-img' />
                                    <p className="amount">Amount : {roundedWalletBalance}</p>
                                </div>
                            }
                        >
                            {close => (
                                <div className='wallet-popup'>
                                    <button type="button" className="wallet-popup-cross-btn" onClick={() => this.onClickClose(close)}>
                                        <RxCross2 />
                                    </button>
                                    {withDrawlView}
                                </div>
                            )}
                        </Popup>
                        <div className='last-transactions' onClick={this.onClickLastTransactions}>
                            <GrTransaction className='transaction-img' />
                            <p>Last Transactions</p>
                        </div>
                    </div>
                    <div className="right-container">
                        <div className='search-container'>
                            <div className="search-inp-container">
                                <input type="search" placeholder='Search' value={searchValue} onChange={this.onChangeSearchValue} className="search-inp" />
                                <FiSearch />
                            </div>
                            <select className='drop-down' id="options" value={selectedOption} onChange={this.onChangeType}>
                                    <option value="all">ALL</option>
                                    <option value="printed">PRINTED</option>
                                    <option value="pending">NOT PRINTED</option>
                            </select>
                            <div>
                                <Switch className='switch' checked={isActive} onChange={this.onChangeActive} checkedChildren="Opened" unCheckedChildren="Closed" />
                            </div>
                        </div>
                        {code}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Home 