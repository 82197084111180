import { Component } from 'react';
import Cookies from 'js-cookie';

import { TiTickOutline } from "react-icons/ti";

import './index.css';

class ServiceItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceTypes: this.props.serviceData.options,
            ServicesErrMsg: '',
            successMsg:''
        };
    }

    renderAllServices = () => {
        const { onClickrenderAllServices } = this.props;
        onClickrenderAllServices();
    };

    handlePriceChange = (event, index, field) => {
        const updatedServiceTypes = [...this.state.serviceTypes];
        const fields = field.split('.'); // Split the field name to handle nested fields like 'bulkPricing.threshold'
        
        let fieldValue = event.target.value;
        
        // Ensure that the bulkPricing object exists if it's being accessed
        if (fields[0] === 'bulkPricing' && !updatedServiceTypes[index].bulkPricing) {
            updatedServiceTypes[index].bulkPricing = {}; // Initialize bulkPricing if it's not defined
        }
        
        // Update the specific field
        if (fields.length === 1) {
            updatedServiceTypes[index][fields[0]] = fieldValue;
        } else if (fields.length === 2) {
            updatedServiceTypes[index][fields[0]][fields[1]] = fieldValue;
        }
    
        this.setState({ serviceTypes: updatedServiceTypes });
    };
    

    onClickSaveServiceItem = async (serviceId) => {
        const { shopId } = this.props;
        const { serviceTypes } = this.state;
    
        // Construct the payload
        const changedData = {
            
            options: serviceTypes.map((option) => ({
                name: option.name,
                price: parseFloat(option.price) || 0,
                ...(option.bulkPricing && {
                    bulkPricing: {
                        threshold: parseInt(option.bulkPricing.threshold, 10) || 0,
                        bulkPrice: parseFloat(option.bulkPricing.bulkPrice) || 0,
                    },
                }),
                ...(option.singleSidePrice !== undefined && {
                    singleSidePrice: parseFloat(option.singleSidePrice) || 0,
                }),
                ...(option.doubleSidePrice !== undefined && {
                    doubleSidePrice: parseFloat(option.doubleSidePrice) || 0,
                }),
            })),
        };
    
        const token = Cookies.get("mitra_token");
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/update-services/${serviceId}`;
        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(changedData),
        };
    
        try {
            const response = await fetch(url, options);
            const data = await response.json();
    
            if (response.ok) {
                this.setState({successMsg:'Service Updated'})
                this.renderAllServices();
            } else {
                console.error('Error:');
                this.setState({ ServicesErrMsg: data.error || 'Something went wrong!' });
            }
        } catch (error) {
            console.error('Network error:', error);
            this.setState({ ServicesErrMsg: 'Network error, please try again.' });
        }
    };

    onClickRemoveServiceItem = async (serviceId) =>{
        const { shopId } = this.props;
        const token=Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/delete-services/${serviceId}`
        const options = {
            headers:{
                Authorization:`Bearer ${token}`,
                'Content-type':'application/json'
            },
            method:"DELETE"
        }
        const response = await fetch(url, options)
        if(response.ok){
            this.renderAllServices();
        }
        else{
            console.log("failure")
        }
    }

    onClickDeleteSubService = async (optionId,serviceId) =>{
        console.log(optionId,"sub service")
        const { shopId } = this.props;
        const token=Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/services/${serviceId}/options/${optionId}`
        const options = {
            headers:{
                Authorization:`Bearer ${token}`,
                'Content-type':'application/json'
            },
            method:"DELETE"
        }
        const response = await fetch(url, options)
        if(response.ok){
            this.renderAllServices();
            window.location.reload();
        }
        else{
            console.log("failure of subservice deleted API")
        }
    }
    

    render() {
        const { serviceData } = this.props;
        const { category, serviceId } = serviceData;
        const { serviceTypes, ServicesErrMsg, successMsg } = this.state;

        if(serviceTypes.length<1){
            this.onClickRemoveServiceItem(serviceId)
        }

        return (
            <div className="service-item">
                <h1 className="sub-heading">{category}</h1>
                <ul className="service-boxes">
                    {serviceTypes.map((eachItem, index) => (
                        <li key={`service-${index}`}>
                            {/* Common Price Field */}
                            {category !== "Printing" && (
                                <div className="input-set">
                                    <label className="profile-label">{eachItem.name} Price</label><br />
                                    <input
                                        className="profile-input"
                                        type="number"
                                        value={eachItem.price || ''}
                                        placeholder="Enter Price"
                                        onChange={(e) => this.handlePriceChange(e, index, 'price')}
                                    />
                                    <button className='delete-sub-service-btn ' onClick={() => this.onClickDeleteSubService(eachItem.optionId,serviceId)}>X</button>
                                </div>
                            )}
                            {/* Single and Double Side Price Fields for Printing */}
                            {category === "Printing" && (
                                <>
                                    <div className="input-set">
                                        <label className="profile-label">{eachItem.name} Single Side Price</label><br />
                                        <input
                                            className="profile-input"
                                            type="number"
                                            value={eachItem.singleSidePrice || ''}
                                            placeholder="Enter Single Side Price"
                                            onChange={(e) => this.handlePriceChange(e, index, 'singleSidePrice')}
                                        />
                                        {eachItem.name==='color' && <button className='delete-sub-service-btn ' onClick={() => this.onClickDeleteSubService(eachItem.optionId,serviceId)}>X</button>}
                                    </div>
                                    <div className="input-set">
                                        <label className="profile-label">{eachItem.name} Double Side Price</label><br />
                                        <input
                                            className="profile-input"
                                            type="number"
                                            value={eachItem.doubleSidePrice || ''}
                                            placeholder="Enter Double Side Price"
                                            onChange={(e) => this.handlePriceChange(e, index, 'doubleSidePrice')}
                                        />
                                        {eachItem.name==='color' && <button className='delete-sub-service-btn ' onClick={() => this.onClickDeleteSubService(eachItem.optionId,serviceId)}>X</button>}
                                    </div>
                                </>
                            )}
                            {/* Bulk Pricing Fields */}
                            {eachItem.bulkPricing.enabled && <div className="input-set">
                                <label className="profile-label">{eachItem.name} Bulk Quantity</label><br />
                                <input
                                    className="profile-input"
                                    type="number"
                                    value={eachItem.bulkPricing?.threshold || ''}
                                    placeholder="Enter Bulk Quantity"
                                    onChange={(e) => this.handlePriceChange(e, index, 'bulkPricing.threshold')}
                                />
                            </div>}
                            {eachItem.bulkPricing.enabled && <div className="input-set">
                                <label className="profile-label">{eachItem.name} Bulk Price</label><br />
                                <input
                                    className="profile-input"
                                    type="number"
                                    value={eachItem.bulkPricing?.bulkPrice || ''}
                                    placeholder="Enter Bulk Price"
                                    onChange={(e) => this.handlePriceChange(e, index, 'bulkPricing.bulkPrice')}
                                />
                            </div>}
                        </li>
                    ))}
                </ul>
                <button className="save-btn" onClick={() => this.onClickSaveServiceItem(serviceId)}>Save</button>
                {category!=='Printing'&& <button className='save-btn remove-btn' onClick={() => this.onClickRemoveServiceItem(serviceId)}>Remove</button>}
                {ServicesErrMsg && <p className="save-error-msg">{ServicesErrMsg}</p>}
                {successMsg && <p className="success-msg">{successMsg} <TiTickOutline className='success-tick' /></p>}
                <hr className="lines" />
            </div>
        );
    }
}

export default ServiceItem;
